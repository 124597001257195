export const dateUtilsMixin = {
  data() {
    return {
      status: {
        INTIME: 1,
        PROMPTPAY: 2,
        OVERDUE: 3,
        NOVALID: 4,
        /**
         * Es parecido a pronto pago solo que este aplica
         * para fechas mucho mas adelantadas a la fecha 
         * de pronto pago
         */
        EARLYPAY: 5 //   
      },
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    }
  },
  methods: {
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1).toISOString().substring(0, 10)
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 1 - 1).toISOString().substring(0, 10)
    },
    getDateWithTimeZeroStr(stringDate) {
      return new Date(stringDate)
    },
    getDateWithTimeZeroFullStr(stringFullDate) {
      return new Date(stringFullDate).toISOString().substring(0, 10)
    },
    getDateWithTimeZero(fullDate) {
      const truncatedDate = fullDate.toISOString().substring(0, 10) 
      return new Date(truncatedDate)
    },
    getDate(fullDate) {
      const tzDate = new Date(fullDate.getTime() - fullDate.getTimezoneOffset() * 60000)
      return tzDate.toISOString().substring(0, 10) 
    },
    getTimeColor(today, date1, date2) {
      if (today < date1) {
        return 'light-green'
      }
      if (today >= date1 && today < date2) {
        return 'success'
      }
      if (today === date2) {
        return 'warning'
      }
      if (today > date2) {
        return 'error'
      }
      return 'purple'
    },
    getTimeStatus(today, date1, date2) {
      if (today < date1) {
        return this.status.EARLYPAY
      }
      if (today >= date1 && today < date2) {
        return this.status.PROMPTPAY
      }
      if (today === date2) {
        return this.status.INTIME
      }
      if (today > date2) {
        return this.status.OVERDUE
      }
      return this.status.NOVALID
    }
  }
}