import { api } from '../api/axios-base'
export const generalFetchingMixin = {
  methods: {
    async fetchEducationalInstitution() {
      try {
        const response = await api.get('/personas/api/institucion-educativa-all/')
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener instituciones educativas', error);
      }
    },
    async fetchStudentsByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/alumno-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener alumnos por institucion educativa', error);
      }
    },
    async fetchGroupsByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/grupo-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener grupos por institucion educativa', error);
      }
    },
    async fetchSuppliersByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/proveedor-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener proveedores por institucion educativa', error);
      }
    },
    async fetchEmployeesByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/empleado-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener empleados por institucion educativa', error);
      }
    },
    async fetchIncomeCategoriesByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/categoria-ingreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener categorias de ingresos por institucion educativa', error);
      }
    },
    async fetchExpenseCategoriesByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/categoria-egreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener categorias de ingresos por institucion educativa', error);
      }
    },
    async fetchExpenseSubCategoriesByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/subcategoria-egreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener sub categorias de ingresos por institucion educativa', error);
      }
    },
    async fetchPaymentMethodsByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/forma-de-pago-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener formas de pago por institucion educativa', error);
      }
    },
    async fetchBankAccountsByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/cuanta-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener formas de pago por institucion educativa', error);
      }
    },
    async fetchDebtsByStudent(
      {
        id_alumno,
        fecha_inicio,
        fecha_fin,
        id_institucion_educativa
      }
    ) {
      try {
        const response = await api.post('/administracion/adeudos-por-alumno', {
            id_alumno: id_alumno,
            fecha_inicio: fecha_inicio,
            fecha_fin: fecha_fin,
            id_institucion_educativa: id_institucion_educativa
        })
        const responseData = await response.data
        // console.log('Respuesta adeudos por alumno', responseData);
        return responseData.resultado
      } catch (error) {
        console.error('Error al obtener adeudos por alumno.', error);
      }
    },
    async fetchDebtsById(debtId, institutionId) {
      try {
        const response = await api.get(`/administracion/api/adeudos-by-ie/${debtId}/?id_institucion=${institutionId}`)
        const responseData = await response.data
        // console.log('Respuesta adeudo por id', responseData);
        return responseData
      } catch (error) {
        console.error('Error al obtener adeudo por id', error);
      }
    },
    async fetchCoinsByIE(institutionId) {
      try {
        const response = await api.get(`administracion/api/moneda-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        // console.log('Respuesta monedas por institucion educativa.', responseData);
        return responseData
      } catch (error) {
        console.error('Error al obtener monedas por institucion educativa', error);
      }
    },
    async fetchPenalties() {
      try {
        const response = await api.get('/administracion/api/penalizaciones-all/')
        const responseData = await response.data
        // console.log('Respuesta todas las penalizaciones', responseData);
        return responseData
      } catch (error) {
        console.error('Error al obtener penalizaciones.', error);
      }
    },
    async fetchDebtStatusByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/estatus-adeudos-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener estatus de adeudo por institucion educativa', error);
      }
    },
    async fetchExpenseStatusByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/estatus-egreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener estatus de egresos por institucion educativa', error);
      }
    },
  }
}