<template>
  <div>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Kinder Nino Logo"
          class="shrink mr-2"
          contain
          :src="require('../assets/images/logokindernino.png')"
          transition="scale-transition"
          width="120"
        />
      </div>

      <v-spacer></v-spacer>
      <v-btn
        text
        @click="exit"
      >
        <span class="text-capitalize mr-2">Salir</span>
      </v-btn>
      <v-btn 
        text
        @click.stop="drawer = !drawer"
      >
        <span class=" text-capitalize mr-2">Menú</span>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <!--<v-btn text>
        <span class=" text-capitalize mr-2">Cerrar</span>
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer
      class="primary"
      v-model="drawer"
      app
      temporary
      right
      dark
    >
      <v-list>
        <v-list-item>
          <v-list-item-content></v-list-item-content>
          <v-list-item-action class="pr-6">
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list dense>
        <v-list-item
          v-for="item in menu"
          :key="item.title"
          link
          :to="{name: item.to}"
        >
          <v-list-item-content class="pl-6">
            <v-list-item-title class="text-subtitle-2">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="pr-6">
            <v-icon color="white">mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-group
          class="pl-6"
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-subtitle-2">Punto de venta</v-list-item-title>
          </template>
          <v-list-item
            v-for="child in pos"
            :key="child.title"
            link
            :to="{name: child.to}"
          >
            <v-list-item-content class="pl-6">
              <v-list-item-title class="text-subtitle-2">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="pr-6">
              <v-icon color="white">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>

        <v-divider class="my-2" dark />
        <v-list-item
          v-for="item in admin"
          :key="item.title"
          link
          :to="{name: item.to}"
        >
          <v-list-item-content class="pl-6">
            <v-list-item-title class="text-subtitle-2">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            v-if="item.icon"
            class="pr-6"
          >
            <v-icon color="white">mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          link
          @click="exit"
        >
          <v-list-item-content class="pl-6">
            <v-list-item-title class="text-subtitle-2">
              Cerrar sesión
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action></v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      drawer: false,
      group: null,
      menu: [
        {
          title: 'Dashboard',
          to: 'Dashboard'
        },
        {
          title: 'Alumnos',
          to: 'Alumnos'
        },
        {
          title: 'Empleados',
          to: 'Empleados'
        },
        {
          title: 'Ingresos',
          to: 'Ingresos'
        },
        {
          title: 'Egresos',
          to: 'Egresos'
        },
        {
          title: 'Proveedores',
          to: 'Proveedores'
        },
        {
          title: 'Productos',
          to: 'Productos'
        },
      ],
      admin: [
        {
          title: 'Configuración',
          to: 'Configuracion',
          icon: true
        }
      ],
      pos: [
        { title: 'Venta', to: 'Venta' },
        { title: 'Ordenes', to: 'Ordenes' },
        { title: 'Inventario', to: 'Inventario'}
      ]
    }
  },
  methods: {
    ...mapActions(['logout']),
    exit() {
      this.$router.replace({ name: 'Login' })
      this.logout()
    }
  }
}
</script>

<style>

</style>