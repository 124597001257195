<template>
  <section>
    <v-container>
      <v-row class="pt-15" justify="center">
        <v-col
          cols="12"
          md="10"
          lg="10"
          xl="10"
        >
          <p class="primary--text text-center text-h5">
            <strong>Bienvenido {{ nameUserLoggedIn }},</strong> a continuación puedes ver un resumen financiero de Kinder Nino&reg;
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          md="10"
          lg="10"
          xl="10"
        >
          <v-row justify="start">
            <v-col
              v-for="chart in charts"
              :key="chart.id"
              :cols="chart.cols"
              :md="chart.md"
            >
              <kn-card-graph
                :title="chart.title"
                :subtitle="chart.subtitle"
                :keyGraph="chart.keyGraph"
                :colsGraph="chart.colsGraph"
                :showText="chart.showText"
                :showGraph="chart.showGraph"
                :bigMount="chart.bigMount"
                :chartData="chart.chartData"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pt-15" justify="center">
        <v-col
          cols="12"
          md="10"
          lg="10"
          xl="10"
        >
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
            >
              <p class="primary--text text-center text-h5"><strong>Reporte de estado</strong></p>
            </v-col>
            <v-col
              cols="4"
              md="1"
              lg="1"
              xl="1"
              class="text-center graydefault--text"
            >Del</v-col>
            <v-col
              cols="8"
              md="2"
              lg="2"
              xl="2"
            >
              <v-text-field
                v-model="dateRange.from"
                dense
                outlined
                type="date"
                hide-details
              />
            </v-col>
            <v-col
              cols="4"
              md="1"
              lg="1"
              xl="1"
              class="text-center graydefault--text"
            >Al</v-col>
            <v-col
              cols="8"
              md="2"
              lg="2"
              xl="2"
            >
              <v-text-field
                v-model="dateRange.to"
                dense
                outlined
                type="date"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              lg="2"
              xl="2"
            >
              <v-btn
                class="ml-2"
                tile
                color="primary"
                :block="$vuetify.breakpoint.mobile"
                @click="filterByDateRange"
              >
                Filtrar
              </v-btn>
            </v-col>
          </v-row>
          
          <v-row justify="center">
            <v-col
              v-for="srchart in statusReportCharts"
              :key="srchart.id"
              :cols="srchart.cols"
              :md="srchart.md"
            >
              <kn-card-graph
                :title="srchart.title"
                :subtitle="srchart.subtitle"
                :keyGraph="srchart.keyGraph"
                :colsGraph="srchart.colsGraph"
                :showText="srchart.showText"
                :showGraph="srchart.showGraph"
                :bigMount="srchart.bigMount"
                :chartData="srchart.chartData"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card flat tile>
                <v-card-title class="py-2 gray1 purple--text">
                  Resumen de Ingresos
                </v-card-title>
                <v-data-table
                  :headers="incomeHeaders"
                  :items="incomeItems"
                  :loading="loading"
                  loading-text="Cargando... Espera por favor"
                  no-data-text="Aún no hay registros"
                  no-results-text="No se encontraron coincidencias"
                  :footer-props="{
                    itemsPerPageText: 'Elementos por página'
                  }"
                >
                  <template #[`item.fecha_elaboracion`]="{ item }">
                      {{ item.fecha_elaboracion.substring(0, 10) }}
                  </template>
                  <template #[`item.total_ingreso`]="{ item }">
                    <span class="green--text">
                      {{ item.total_ingreso }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card flat tile>
                <v-card-title class="py-2 gray1 purple--text">
                  Resumen de Egresos
                </v-card-title>
                <v-data-table
                  :headers="expenseHeaders"
                  :items="expenseItems"
                  :loading="loading"
                  loading-text="Cargando... Espera por favor"
                  no-data-text="Aún no hay registros"
                  no-results-text="No se encontraron coincidencias"
                  :footer-props="{
                    itemsPerPageText: 'Elementos por página'
                  }"
                >
                  <template #[`item.fecha_elaboracion`]="{ item }">
                      {{ item.fecha_elaboracion.substring(0, 10) }}
                  </template>
                  <template #[`item.total_egreso`]="{ item }">
                    <span class="green--text">
                      {{ item.total_egreso }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import KnCardGraph from '@/components/dashboard/KnCardGraph.vue'
import { generalFetchingMixin } from '@/mixins/generalFetchingMixin'
import { api } from '@/api/axios-base'
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin'
export default {
  components: { KnCardGraph },
  mixins: [generalFetchingMixin, dateUtilsMixin],
  data() {
    return {
      charts: [],
      statusReportCharts: [], 
      students: [],
      employees: [],
      suppliers: [],
      statusReportResults: [],
      dateRange: {
        from: '',
        to: ''
      },
      today: null,
      currentYear: null,
      currentMonth: null,
      lastMonths: [],
      loading: false,
      incomeHeaders: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        { text: 'Alumno', value: 'nombre', class: 'purple--text' }, 
        { text: 'Concepto', value: 'concepto', class: 'purple--text' },
        { text: 'Subtotal', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Descuento', value: 'total_descuento', class: 'purple--text' },
        { text: 'Penalizacion', value: 'total_penalizaciones', class: 'purple--text' },
        { text: 'Total', value: 'total_ingreso', class: 'purple--text' },
        { text: 'Forma de pago', value: 'forma_de_pago.dato', class: 'purple--text' },
        { text: 'Cuenta', value: 'cuenta_destino.nombre_cuenta', class: 'purple--text' },
        { text: 'Balance', value: 'cuenta_destino.balance', class: 'purple--text' },
      ],
      expenseHeaders: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        { text: 'Nombre o razón social', value: 'nombre', class: 'purple--text' },
        { text: 'Receptor', value: 'receptor', class: 'purple--text' },
        { text: 'Concepto', value: 'concepto', class: 'purple--text' },
        { text: 'Subtotal', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Descuento', value: 'total_descuento', class: 'purple--text' },
        { text: 'Total', value: 'total_egreso', class: 'purple--text' },
        { text: 'Forma de pago', value: 'forma_de_pago.dato', class: 'purple--text' },
        { text: 'Cuenta', value: 'cuenta_origen.nombre_cuenta', class: 'purple--text' },
        { text: 'Balance', value: 'cuenta_origen.balance', class: 'purple--text' },
      ],
      incomeItems: [],
      expenseItems: []
    }
  },
  computed: {
    ...mapState(['institutionId']),
    ...mapGetters(['nameUserLoggedIn'])
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    this.students = await this.fetchStudentsByEI(this.institutionId)
    this.employees = await this.fetchEmployeesByEI(this.institutionId)
    this.suppliers = await this.fetchSuppliersByEI(this.institutionId)
    this.today = new Date()
    this.currentYear = this.today.getFullYear()
    this.currentMonth = this.today.getMonth()
    this.dateRange.from = this.getFirstDayOfMonth(this.currentYear, this.currentMonth)
    this.dateRange.to = this.getLastDayOfMonth(this.currentYear, this.currentMonth)

    this.statusReportResults = await this.fetchStatusReport()

    await this.buildChartIncomeByStudents()
    await this.buildChartExpenseByEmployee()
    await this.buildChartExpenseBySupplier()

    this.lastMonths = this.calculateLastMonths(6)
    // console.log('ultimos seis meses:', this.lastMonths);

    await this.buildChartIncomeLastSixMonths()
    await this.buildChartExpenseLastSixMonths()

    this.buildStatusReportCharts()
    this.buildIncomeTable()
    this.buildExpenseTable()

    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async fetchTotalIncomeByStudent(studentId) {
      try {
        const response = await api.post('/administracion/ingresos-por-alumno', {
          id_alumno: studentId,
          fecha_inicio: this.dateRange.from,
          fecha_fin: this.dateRange.to,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        return responseData.resultado.total_ingreso__sum
      } catch (error) {
        console.error('Error al obtener total de ingreso al mes por alumno', error);
      }
    },
    async fetchTotalExpenseByEmployee(employeeId) {
      try {
        const response = await api.post('/administracion/egreso-por-empleado', {
          id_empleado: employeeId,
          fecha_inicio: this.dateRange.from,
          fecha_fin: this.dateRange.to,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        return responseData.resultado.total_egreso__sum
      } catch (error) {
        console.error('Error al obtener total de egreso al mes por empleado', error);
      }
    },
    async fetchTotalExpenseBySupplier(supplierId) {
      try {
        const response = await api.post('/administracion/egresos-proveedor', {
          id_proveedor: supplierId,
          fecha_inicio: this.dateRange.from,
          fecha_fin: this.dateRange.to,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        return responseData.resultado.total_egreso__sum
      } catch (error) {
        console.error('Error al obtener total de egreso al mes por empleado', error);
      }
    },
    async fetchTotalIncomeByMonth({firstDay, lastDay}) {
      try {
        const response = await api.post('/administracion/get-ingresos-mes', {
          fecha_inicio: firstDay,
          fecha_fin: lastDay,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        return responseData.resultado.total_ingreso__sum
      } catch (error) {
        console.error('Error al obtener total de ingreso del mes', error);
      }
    },
    async fetchTotalExpenseByMonth({firstDay, lastDay}) {
      try {
        const response = await api.post('/administracion/get-egresos-mes', {
          fecha_inicio: firstDay,
          fecha_fin: lastDay,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        return responseData.resultado.total_egreso__sum
      } catch (error) {
        console.error('Error al obtener total de egreso del mes', error);
      }
    },
    async fetchStatusReport() {
      try {
        const response = await api.post('/administracion/estado-resultados-mes', {
          fecha_inicio: this.dateRange.from,
          fecha_fin: this.dateRange.to,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener estado de resultados', error);
      }
    },
    async filterByDateRange() {
      this.loading = true
      this.charts = []
      this.statusReportCharts = []
      this.statusReportResults = await this.fetchStatusReport()

      await this.buildChartIncomeByStudents()
      await this.buildChartExpenseByEmployee()
      await this.buildChartExpenseBySupplier()

      await this.buildChartIncomeLastSixMonths()
      await this.buildChartExpenseLastSixMonths()

      this.buildStatusReportCharts()
      this.buildIncomeTable()
      this.buildExpenseTable()
      this.loading = false
    },
    async buildChartIncomeByStudents() {
      let labels = [] // Nombre de los alumnos
      let data = [] // Montos de ingreso
      let total = 0.00
      for (const student of this.students) {
        const {
          primer_nombre: pn,
          segundo_nombre: sn,
          apellido_paterno: ap,
          apellido_materno: am
        } = student.datos_personales
        const fullName = `${pn} ${sn} ${ap} ${am}`
        labels.push(fullName)
        student.nombre = fullName
        let mount = 0.00
        const value = await this.fetchTotalIncomeByStudent(student.id)
        if (value !== undefined && value !== null) {
          mount = parseFloat(value)
        } 
        // console.log('Monto: ', mount);
        data.push(mount)
      }
      total = data.reduce((a, b) => a + b, 0)
      // console.log('Big mount:', total);

      const dataset = {
        label: 'Monto:',
        data: [...data],
        backgroundColor: "rgba(19, 111, 238, .9)", // Azul claro - info
        borderColor: "#0047BA", // Azul oscuro - primary
        borderWidth: 3,
        borderRadius: 0
      }
      const chart = {
        id: 'g1',
        title: `Ingresos por alumno`,
        subtitle: `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`,
        keyGraph: 'ingal',
        colsGraph: total > 0.00 ? 12 : 1,
        showText: true,
        showGraph: (total > 0.00),
        bigMount: `+$${total}`,
        chartData: {
          type: 'bar',
          data: {
            labels: [...labels],
            datasets: [{...dataset}]
          }
        },
        cols: 12,
        md: total > 0.00 ? 6 : 4
      }
      // console.log('chart obj', chart);
      
      this.charts.push(chart)
    },
    async buildChartExpenseByEmployee() {
      let labels = [] // Nombre de los empleados
      let data = [] // Montos de egreso
      let total = 0.00
      for (const employee of this.employees) {
        const {
          primer_nombre: pn,
          segundo_nombre: sn,
          apellido_paterno: ap,
          apellido_materno: am
        } = employee.datos_personales
        const fullName = `${pn} ${sn} ${ap} ${am}`
        labels.push(fullName)
        employee.nombre = fullName
        let mount = 0.00
        const value = await this.fetchTotalExpenseByEmployee(employee.id)
        if (value !== undefined && value !== null) {
          mount = parseFloat(value)
        } 
        // console.log('Monto: ', mount);
        data.push(mount)
      }
      total = data.reduce((a, b) => a + b, 0)
      // console.log('Big mount:', total);

      const dataset = {
        label: 'Monto',
        data: [...data],
        backgroundColor: "rgba(255, 182, 0, 1)", // Amarillo - warning
        borderColor: "#ff5f00", // Naranja - error
        borderWidth: 3,
        borderRadius: 0
      }
      const chart = {
        id: 'g2',
        title: `Egresos por empleado`,
        subtitle: `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`,
        keyGraph: 'egemp',
        colsGraph: total > 0.00 ? 12 : 1,
        showText: true,
        showGraph: (total > 0.00),
        bigMount: `-$${total}`,
        chartData: {
          type: 'bar',
          data: {
            labels: [...labels],
            datasets: [{...dataset}]
          }
        },
        cols: 12,
        md: total > 0.00 ? 6 : 4
      }
      // console.log('chart obj', chart);
      
      this.charts.push(chart)
    },
    async buildChartExpenseBySupplier() {
      let labels = [] // Nombre de los proveedores
      let data = [] // Montos de egreso
      let total = 0.00
      for (const supplier of this.suppliers) {
        let supplierName = ''
        if (supplier.nombre_proveedor) {
          supplierName = supplier.nombre_proveedor
        } else if (supplier.datos_fiscales && supplier.datos_fiscales.length > 0) {
          supplierName = supplier.datos_fiscales[0].nombre_o_razon_social
        } else if (supplier.usuario && supplier.usuario.username) {
          supplierName = supplier.usuario.username
        } else {
          supplierName = 'Sin nombre'
        }
        
        labels.push(supplierName)
        supplier.nombre = supplierName
        let mount = 0.00
        const value = await this.fetchTotalExpenseBySupplier(supplier.id)
        if (value !== undefined && value !== null) {
          mount = parseFloat(value)
        } 
        // console.log('Monto: ', mount);
        data.push(mount)
      }
      total = data.reduce((a, b) => a + b, 0)
      // console.log('Big mount:', total);

      const dataset = {
        label: 'Monto',
        data: [...data],
        backgroundColor: "rgba(255, 114, 118, 1)", // Amarillo - warning
        borderColor: "#F6DFA4", // Naranja - error
        borderWidth: 3,
        borderRadius: 0
      }
      const chart = {
        id: 'g5',
        title: `Egresos por proveedor`,
        subtitle: `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`,
        keyGraph: 'egprov',
        colsGraph: total > 0.00 ? 12 : 1,
        showText: true,
        showGraph: (total > 0.00),
        bigMount: `-$${total}`,
        chartData: {
          type: 'bar',
          data: {
            labels: [...labels],
            datasets: [{...dataset}]
          }
        },
        cols: 12,
        md: total > 0.00 ? 6 : 4
      }
      // console.log('chart obj', chart);
      
      this.charts.push(chart)
    },
    async buildChartIncomeLastSixMonths() {
      let labels = [] // Nombre de los meses
      let data = [] // Montos de ingreso
      let total = 0.00
      for (const month of this.lastMonths) {
        labels.push(month.name)
        let mount = 0.00
        const value = await this.fetchTotalIncomeByMonth(month)
        if (value !== undefined && value !== null) {
          mount = parseFloat(value)
        } 
        // console.log('Monto: ', mount);
        data.push(mount)
      }
      total = data.reduce((a, b) => a + b, 0)
      // console.log('Big mount:', total);

      const firstMonth = this.lastMonths[0].name
      const lastMonth = this.lastMonths[this.lastMonths.length - 1].name
      const dataset = {
        label: 'Ingreso del mes',
        data: [...data],
        backgroundColor: "rgba(0, 174, 66, .5)", // verde - success
        borderColor: "#00ae42", // verde - success
        borderWidth: 3,
        borderRadius: 0
      }
      const chart = {
        id: 'g3',
        title: `Ingresos últimos seis meses`,
        subtitle: `Periodo:  ${firstMonth} - ${lastMonth}`,
        keyGraph: 'inmes',
        colsGraph: total > 0.00 ? 12 : 1,
        showText: true,
        showGraph: (total > 0.00),
        bigMount: `+$${total}`,
        chartData: {
          type: 'line',
          data: {
            labels: [...labels],
            datasets: [{...dataset}]
          }
        },
        cols: 12,
        md: total > 0.00 ? 4 : 4
      }
      // console.log('chart obj', chart);
      
      this.charts.push(chart)
    },
    async buildChartExpenseLastSixMonths() {
      let labels = [] // Nombre de los meses
      let data = [] // Montos de egreso
      let total = 0.00
      for (const month of this.lastMonths) {
        labels.push(month.name)
        let mount = 0.00
        const value = await this.fetchTotalExpenseByMonth(month)
        if (value !== undefined && value !== null) {
          mount = parseFloat(value)
        } 
        // console.log('Monto: ', mount);
        data.push(mount)
      }
      total = data.reduce((a, b) => a + b, 0)
      // console.log('Big mount:', total);

      const firstMonth = this.lastMonths[0].name
      const lastMonth = this.lastMonths[this.lastMonths.length - 1].name
      const dataset = {
        label: 'Egreso del mes',
        data: [...data],
        backgroundColor: "rgba(255, 95, 0, .5)", // verde - success
        borderColor: "#ff5f00", // verde - success
        borderWidth: 3,
        borderRadius: 0
      }
      const chart = {
        id: 'g4',
        title: `Egresos últimos seis meses`,
        subtitle: `Periodo:  ${firstMonth} - ${lastMonth}`,
        keyGraph: 'egmes',
        colsGraph: total > 0.00 ? 12 : 1,
        showText: true,
        showGraph: (total > 0.00),
        bigMount: `-$${total}`,
        chartData: {
          type: 'line',
          data: {
            labels: [...labels],
            datasets: [{...dataset}]
          }
        },
        cols: 12,
        md: total > 0.00 ? 4 : 4
      }
      // console.log('chart obj', chart);
      
      this.charts.push(chart)
    },
    buildStatusReportCharts() {
      // console.log('Resultados de reporte de estatus: ', this.statusReportResults);
      const periodText = `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`
      const chartType = 'doughnut'
      const chartColsMax = 10
      const {
        total_ingresos_por_categoria,
        total_ingresos_por_cuenta,
        total_ingresos_por_forma_pago,
        total_egresos_por_categoria,
        // total_egresos_por_proveedor,
        total_egresos_por_cuenta,
        total_egresos_por_centro_de_costos,
        total_egresos_forma_pago
      } = this.statusReportResults

      /** Gráficas de categorias */
      const incomeCategoryChart = this.statusReportChart(
        total_ingresos_por_categoria,
        'categoria',
        'total_categoria',
        'total_ingreso__sum',
        'g6',
        'insta', 
        chartType,
        'Ingresos por categoría',
        periodText,
        '+$',
        1,
        chartColsMax,
        4,
        4
      )

      const expenseCategoryChart = this.statusReportChart(
        total_egresos_por_categoria,
        'categoria',
        'total_categoria',
        'total_egreso__sum',
        'g7',
        'egsta', 
        chartType,
        'Egresos por categoría',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      )
      
      /** Gráficas de cuentas bancarias */
      const incomeAccountChart = this.statusReportChart(
        total_ingresos_por_cuenta,
        'cuenta',
        'total_cuenta',
        'total_ingreso__sum',
        'g8',
        'incue', 
        chartType,
        'Ingresos por cuenta bancaria',
        periodText,
        '+$',
        1,
        chartColsMax,
        4,
        4
      )

      const expenseAccountChart = this.statusReportChart(
        total_egresos_por_cuenta,
        'cuenta',
        'total_cuenta',
        'total_egreso__sum',
        'g9',
        'egcue', 
        chartType,
        'Egresos por cuenta bancaria',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      )
      

      /** Gráficas de formas de pago */
      const incomePaymentMethodsChart = this.statusReportChart(
        total_ingresos_por_forma_pago,
        'forma',
        'total_forma_pago',
        'total_ingreso__sum',
        'g10',
        'infpa', 
        chartType,
        'Ingresos por forma de pago',
        periodText,
        '+$',
        1,
        chartColsMax,
        4,
        4
      )
      
      const expensePaymentMethodsChart = this.statusReportChart(
        total_egresos_forma_pago,
        'forma',
        'total_forma_pago',
        'total_egreso__sum',
        'g11',
        'egfpa', 
        chartType,
        'Egresos por forma de pago',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      )

      /** Gráfica de centros de costos */
      const expenseCostCenterChart = this.statusReportChart(
        total_egresos_por_centro_de_costos,
        'centro_de_costos',
        'total_centro',
        'total_egreso__sum',
        'g12',
        'egcco', 
        chartType,
        'Egresos por centro de costos',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      )

      /** Acomodando graficas
       * Primero todos los ingresos
       * Despues todos los egresos
      */
      this.statusReportCharts.push(incomeCategoryChart)
      this.statusReportCharts.push(incomeAccountChart)
      this.statusReportCharts.push(incomePaymentMethodsChart)
      this.statusReportCharts.push(expenseCategoryChart)
      this.statusReportCharts.push(expenseAccountChart)
      this.statusReportCharts.push(expensePaymentMethodsChart)
      this.statusReportCharts.push(expenseCostCenterChart)
    },
    buildIncomeTable() {
      const { ingresos } = this.statusReportResults
      ingresos.forEach(income => {
        const student = this.students.find(std => income.alumno ? std.id === income.alumno.id : false)
        const fullName = student ? student.nombre : 'No aplica'
        income.nombre = fullName
        const debtName = income.adeudo.map(debt => debt.nombre_adeudo ? debt.nombre_adeudo : debt.comentarios)
        let conceptComplement = ''
        if (debtName.length > 0) {
          conceptComplement = `| ${debtName.join(', ')}`  
        }
        const concept = `${income.categoria.dato} ${conceptComplement}`
        income.concepto = concept
      })
      this.incomeItems = [...ingresos]
    },
    buildExpenseTable() {
      const { egresos } = this.statusReportResults
      let person = null
      egresos.forEach(expense => {
        const receiver = expense.proveedor 
          ? 'Proveedor' 
          : (expense.empleado 
            ? 'Empleado' 
            : 'No aplica')
        expense.receptor = receiver
        switch(receiver) {
          case 'Proveedor':
            person = this.suppliers.find(supplier =>  supplier.id === expense.proveedor.id)
            expense.nombre = person ? person.nombre : 'No aplica'
            break
          case 'Empleado':
            person = this.employees.find(employee => employee.id === expense.empleado.id)
            expense.nombre = person ? person.nombre : 'No aplica'
            break
          default: 
            expense.nombre = 'No aplica'
            break
        }
        const concept = `${expense.categoria.dato} - ${expense.sub_categoria.dato}`
        expense.concepto = concept
      })
      this.expenseItems = [...egresos]
    },
    calculateLastMonths(nMonths) {
      let lastNMonths = []
      
      for (let i = nMonths; i >= 0; i--) {
        const from = this.getFirstDayOfMonth(this.currentYear, this.currentMonth - i)
        const to = this.getLastDayOfMonth(this.currentYear, this.currentMonth - i)
        const toDate = new Date(to)
        const toMonth = toDate.getMonth()
        const monthName = this.months[toMonth]
        let month = {
          name: monthName,
          firstDay: from,
          lastDay:  to
        }
        lastNMonths.push(month)
      }

      return lastNMonths
    },
    generateRandomColor() {
      const randomValue = this.getRandomHexNumber(16777215)
      const randomValueLength = randomValue.length
      const spaces = 6 - randomValueLength
      let valuesForComplete = ''
      for (let i = 0; i < spaces; i++) {
        const value = this.getRandomHexNumber(15)
        valuesForComplete += value
      }
      return `#${randomValue}${valuesForComplete}b2`
    },
    getRandomHexNumber(limit) {
      return Math.floor(Math.random()*limit).toString(16)
    },
    configChart(
      canvasId,
      chartKey,
      chartType,
      chartTitle,
      chartSubtitle,
      labels,
      dataset,
      total,
      prefixBigMount = '$',
      chartColsMin = 1,
      chartColsMax = 12,
      cardMdMin = 4,
      cardMdMax = 6,
      cardColsMax = 12
    ) {
      const chart = {
        id: canvasId,
        title: chartTitle,
        subtitle: chartSubtitle,
        keyGraph: chartKey,
        colsGraph: total > 0.00 ? chartColsMax : chartColsMin,
        showText: true,
        showGraph: (total > 0.00),
        bigMount: `${prefixBigMount}${total}`,
        chartData: {
          type: chartType,
          data: {
            labels: [...labels],
            datasets: [{...dataset}]
          }
        },
        cols: cardColsMax,
        md: total > 0.00 ? cardMdMax : cardMdMin
      }
      return chart
    },
    getLabelsArray(array, labelKey) {
      return array.map(item => item[labelKey])
    },
    getTotalsArray(array, fatherLabelKey, childLabelKey = null) {
      return array.map(item => childLabelKey !== null ? parseFloat(item[fatherLabelKey][childLabelKey]) : parseFloat(item[fatherLabelKey]))
    },
    getTotalAmount(array) {
      return array.reduce((a, b) => a + b, 0)
    },
    getColorArray(arrayLength) {
      const colorArray = []
      for (let i = 0; i < arrayLength; i++) {
        colorArray.push(this.generateRandomColor())
      }
      return colorArray
    },
    statusReportChart(
      array,
      labelKey,
      containerAmountKey,
      sumAmountKey,
      canvasId,
      chartKey,
      chartType,
      chartTitle,
      chartSubtitle,
      prefixBigMount = '$',
      chartColsMin = 1,
      chartColsMax = 12,
      cardMdMin = 4,
      cardMdMax = 6,
      cardColsMax = 12
    ) {
      const labels = this.getLabelsArray(array, labelKey)                                 
      const totals = this.getTotalsArray(array, containerAmountKey, sumAmountKey)
      const total = this.getTotalAmount(totals)
      const colors = this.getColorArray(labels.length)
      // console.log('colors de ', labelKey, colors);

      const dataset = {
        label: chartTitle,
        data: [...totals],
        backgroundColor: [...colors],
      }
      const chartObj = this.configChart(
        canvasId,
        chartKey, 
        chartType,
        chartTitle,
        chartSubtitle,
        labels,
        dataset,
        total,
        prefixBigMount,
        chartColsMin,
        chartColsMax,
        cardMdMin,
        cardMdMax,
        cardColsMax
      )
      return chartObj
    }
  }
}
</script>
