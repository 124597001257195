import Vue from 'vue'
import Vuex from 'vuex'
import { commonAPI, api } from '../api/axios-base'
import router from '../router'
import pos from './modules/pos'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profilesConsts: {
      SUPERADMIN: 1,
      ADMINEMPLOYEE: 2,
      BASICEMPLOYEE: 3,
      STUDENT: 4,
      TUTOR: 6,
      SUPPLIER: 7
    },
    isLogin: localStorage.getItem('isLogin') || false,
    isPOS: false,
    userData: JSON.parse(localStorage.getItem('userData')) || null,
    apiTokens: JSON.parse(localStorage.getItem('apiTokens')) || null,
    userProfile: parseInt(localStorage.getItem('userProfile')) || null,
    institutionId: parseInt(localStorage.getItem('institutionId')) || null,
    warehouse: JSON.parse(localStorage.getItem('warehouse')) || null,
    showAlert: false,
    alertType: 'error',
    alertText: 'Espere, por favor',
    loadingAlert: false,
  },
  mutations: {
    setIsLogin: (state, payload) => (state.isLogin = payload),
    setIsPOS: (state, payload) => (state.isPOS = payload),
    setUserData: (state, payload) => (state.userData = payload),
    setApiTokens: (state, payload) => (state.apiTokens = payload),
    setUserProfile: (state, payload) => (state.userProfile = payload),
    setInstitutionId: (state, payload) => (state.institutionId = payload),
    setWarehouse: (state, payload) => (state.warehouse = payload),
    setShowAlert: (state, payload) => (state.showAlert = payload),
    setAlertType: (state, payload) => (state.alertType = payload),
    setAlertText: (state, payload) => (state.alertText = payload),
    setLoadingAlert: (state, payload) => (state.loadingAlert = payload)
  },
  actions: {
    async login({ commit, dispatch }, userCredentials) {
      try {
        const response = await commonAPI.post('/land/api/token/', userCredentials)
        const responseData = await response.data

        // console.log('Resultado response login: ', response);
        // console.log('Resultado responseData login: ', responseData);

        if (response.status === 200) {
          const { access, refresh } = responseData
          commit('setApiTokens', { access, refresh })
          localStorage.setItem('apiTokens', JSON.stringify({ access, refresh }))

          commit('setUserData', responseData)
          localStorage.setItem('userData', JSON.stringify(responseData))

          await dispatch('setUserProfile')
          await dispatch('setWarehouse')
          router.replace({ name: 'Dashboard' })
        }
      } catch (error) {
        console.error('Error al obtener token');
      }
    },
    /// AUTOR: Tania Marin
    /// RESEÑANTE: Raúl Altamirano Lozano	
    /// FECHA: 29/07/2022
    /// COMENTARIOS:
    /// - Verificar el refresh token, cada que el usuario ingresa después de un tiempo.
    async refreshToken({ commit, state, dispatch }) {
      try {
        const refreshToken = state.apiTokens.refresh
        const response = await commonAPI.post('/land/api/token/refresh/', {
          refresh: refreshToken
        })
        const responseData = await response.data
          const tokens = { access: responseData.access, refresh: refreshToken }
          commit('setApiTokens', tokens)
          localStorage.setItem('apiTokens', JSON.stringify(tokens))
      } catch (error) {
        console.error('Error al obtener refreshToken: ', error);
        dispatch('logout')
        router.replace({ name: 'Login' })
      }
    },
    async setWarehouse({ commit, state }) {
      try {
        const institutionId = state.institutionId
        const response = await api.get(`/inventarios/api/almacen-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data

        const allWarehouse = responseData
        const defaultWarehouse = allWarehouse.find(warehouse => warehouse.almacen_predeterminado === true)

        commit('setWarehouse', defaultWarehouse)
        localStorage.setItem('warehouse', JSON.stringify(defaultWarehouse))
      } catch (error) {
        console.error('Error al intentar establecer almacen', error);
      }
    },
    logout({ commit }) {
      commit('setIsLogin', true)
      commit('setApiTokens', null)
      commit('setUserData', null)
      commit('setUserProfile', null)
      commit('setInstitutionId', null)
      commit('setWarehouse', null)

      localStorage.removeItem('isLogin')
      localStorage.removeItem('apiTokens')
      localStorage.removeItem('userData')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('institutionId')
      localStorage.removeItem('warehouse')
    },
    async setUserProfile({ commit, state }) {
      try {
        let userProfile = null
        let institutionId = null
        const profiles = state.userData.perfiles
        const userProfiles = profiles.filter(profile => {
          return ((profile.proveedor !== null && profile.proveedor !== undefined) 
            || (profile.alumno !== null && profile.alumno !== undefined)
            || (profile.tutor !== null && profile.tutor !== undefined)
            || (profile.empleado !== null && profile.empleado !== undefined))
        })
        // console.log('userProfiles: ', userProfiles);
        // console.log('constantes tipos de usuario: ', state.profilesConsts);
        if (userProfiles.length > 1) {
          userProfile = state.profilesConsts.SUPERADMIN
        } else {
          for (const p of profiles ) {
            // console.log('profile: ', p);
            if (p.proveedor !== null && p.proveedor !== undefined) {
              // console.log('Es Proveedor');
              userProfile = state.profilesConsts.SUPPLIER
              institutionId = p.proveedor[0].institucion_educativa_id
            } else if (p.alumno !== null && p.alumno !== undefined) {
              // console.log('Es alumno');
              userProfile = state.profilesConsts.STUDENT
              institutionId = p.alumno[0].institucion_educativa_id
            } else if (p.tutor !== null && p.tutor !== undefined) {
              // console.log('Es tutor');
              userProfile = state.profilesConsts.TUTOR
              institutionId = p.tutor[0].institucion_educativa_id
            } if (p.empleado !== null && p.empleado !== undefined) {
              // console.log('Es empleado: ', p.empleado);
              const employeeTypeId = p.empleado[0].tipo_empleado_id
              institutionId = p.empleado[0].institucion_educativa_id

              const response = await api.get(`/personas/api/tipo-empleado-by-ie/${employeeTypeId}/?id_institucion=${institutionId}`)
              const responseData = await response.data
              const employeeType = responseData.dato
              // console.log('Tipo empleado: ', employeeType);
              userProfile = employeeType === 'Administrativo' 
                ? state.profilesConsts.ADMINEMPLOYEE 
                : state.profilesConsts.BASICEMPLOYEE
              
            }
          }
        }
        // console.log('userProfile selected: ', userProfile);
        // console.log('institutionId selected: ', institutionId)
        commit('setUserProfile', userProfile)
        localStorage.setItem('userProfile', userProfile)

        commit('setInstitutionId', institutionId)
        localStorage.setItem('institutionId', institutionId)
      } catch (error) {
        console.error('Error al determinar perfil del usuario: ',error);
      }
    },
    showAlert({ commit }, { alertText, alertType, loadingAlert = true }) {
      commit('setAlertType', alertType)
      commit('setAlertText', alertText)
      commit('setLoadingAlert', loadingAlert)
      commit('setShowAlert', true)
    },
    stopLoadingAlert({ commit }) {
      commit('setLoadingAlert', false)
    },
    hideAlert({ commit }) {
      commit('setShowAlert', false)
    }
  },
  getters: {
    isLoggedIn: (state) => state.userData !== null ? true : false,
    nameUserLoggedIn: (state) => {
      const userData = state.userData
      let fullName = ''
      if (userData != null) {
        const { nombre, apellido } = userData
        fullName = `${nombre} ${apellido}`
      }
      return fullName
    },
    isSuperAdmin: (state) => state.userProfile === state.profilesConsts.SUPERADMIN,
    isAdminEmployee: (state) => state.userProfile === state.profilesConsts.ADMINEMPLOYEE,
    isBasicEmployee: (state) => state.userProfile === state.profilesConsts.BASICEMPLOYEE
  },
  modules: {
    pos
  }
})
