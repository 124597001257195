import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/dashboard/Index.vue'
import store from '../store'

Vue.use(VueRouter)

/// AUTOR: Tania Marin
/// RESEÑANTE: Raúl Altamirano Lozano	
/// FECHA: 29/07/2022
/// COMENTARIOS:
/// - Limitar el tipo de usuario que debe ingresar al sistema
/// - Determinar las páginas que deben estar habilitadas para cada usuario dependiendo de su nivel de accesos.

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    /**
     * Se deja la validación en cada ruta debido a que
     * cuando se accede a las rutas desde la url en el
     * navegador (localhost/{cualquier path}) no se
     * respetan las condiciones establecidas en el main.js
     * y deja visualizar las páginas aun si no se cumplen
     * las condiciones.
     */
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/alumnos',
    name: 'Alumnos',
    component: () => import(/* webpackChunkName: "students" */ '../views/students/Index.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/alumnos/nuevo',
    name: 'Nuevo Alumno',
    component: () => import(/* webpackChunkName: "new_students" */ '../views/students/KnFormStudent.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/alumnos/editar',
    name: 'Editar Alumno',
    component: () => import(/* webpackChunkName: "edit_students" */ '../views/students/KnFormStudent.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/alumnos/estado-cuenta',
    name: 'Estado de Cuenta Alumno',
    component: () => import(/* webpackChunkName: "account_status_students" */ '../views/students/KnStudentAccountStatus.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/empleados',
    name: 'Empleados',
    component: () => import(/* webpackChunkName: "employees" */ '../views/employees/Index.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/empleados/nuevo',
    name: 'Nuevo Empleado',
    component: () => import(/* webpackChunkName: "new_employees" */ '../views/employees/KnFormEmployee.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/empleados/editar',
    name: 'Editar Empleado',
    component: () => import(/* webpackChunkName: "edit_employees" */ '../views/employees/KnFormEmployee.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/empleados/estado-cuenta',
    name: 'Estado de Cuenta Empleado',
    component: () => import(/* webpackChunkName: "account_status_employee" */ '../views/employees/KnEmployeeAccountStatus.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/ingresos',
    name: 'Ingresos',
    component: () => import(/* webpackChunkName: "income" */ '../views/income/Index.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/ingresos/nuevo',
    name: 'Agregar Ingresos',
    component: () => import(/* webpackChunkName: "new_income" */ '../views/income/KnFormIncome.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/ingresos/editar',
    name: 'Editar Ingresos',
    component: () => import(/* webpackChunkName: "edit_income" */ '../views/income/KnFormIncome.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/egresos',
    name: 'Egresos',
    component: () => import(/* webpackChunkName: "expenses" */ '../views/expenses/Index.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/egresos/nuevo',
    name: 'Agregar Egresos',
    component: () => import(/* webpackChunkName: "new_expenses" */ '../views/expenses/KnFormExpenses.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/egresos/editar',
    name: 'Editar Egresos',
    component: () => import(/* webpackChunkName: "edit_expenses" */ '../views/expenses/KnFormExpenses.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/configuracion',
    name: 'Configuracion',
    component: () => import(/* webpackChunkName: "configuration" */ '../views/configuration/Index.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/proveedores',
    name: 'Proveedores',
    component: () => import(/* webpackChunkName: "suppliers" */ '../views/suppliers/Index.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/proveedores/nuevo',
    name: 'Nuevo Proveedor',
    component: () => import(/* webpackChunkName: "new_suppliers" */ '../views/suppliers/KnFormSupplier.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/proveedores/editar',
    name: 'Editar Proveedor',
    component: () => import(/* webpackChunkName: "edit_suppliers" */ '../views/suppliers/KnFormSupplier.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/productos',
    name: 'Productos',
    component: () => import(/* webpackChunkName: "products" */ '../views/products/Index.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/productos/nuevo',
    name: 'Nuevo Producto',
    component: () => import(/* webpackChunkName: "new_products" */ '../views/products/KnFormProduct.vue'),
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/productos/editar',
    name: 'Editar Producto',
    component: () => import(/* webpackChunkName: "edit_products" */ '../views/products/KnFormProduct.vue'),
    props: true,
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/punto-de-venta',
    name: 'POS',
    component: () => import(/* webpackChunkName: "pos" */ '../views/pos/Index.vue'),
    redirect: { name: 'Venta' },
    children: [
      {
        path: 'venta',
        name: 'Venta',
        component: () => import(/* webpackChunkName: "pos_sale" */ '../views/pos/sale/KnSale.vue'),
        meta: {
          requireAuth: true,
          adminAccess: true
        }
      },
      {
        path: 'ordenes',
        name: 'Ordenes',
        component: () => import(/* webpackChunkName: "pos_orders" */ '../views/pos/orders/KnOrders.vue'),
        meta: {
          requireAuth: true,
          adminAccess: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (to.meta.requireAuth) {
            if (!store.getters.isLoggedIn) {
              next({ name: 'Login' })
            } else {
              if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
                next()
              } else {
                next({ name: 'Acceso restringido' })
              }
            }
          } else {
            next()
          }
        }
      },
      {
        path: 'ordenes/nuevo',
        name: 'Nuevo Ordenes',
        component: () => import(/* webpackChunkName: "pos_new_orders" */ '../views/pos/orders/KnFormOrder.vue'),
        props: true,
        meta: {
          requireAuth: true,
          adminAccess: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (to.meta.requireAuth) {
            if (!store.getters.isLoggedIn) {
              next({ name: 'Login' })
            } else {
              if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
                next()
              } else {
                next({ name: 'Acceso restringido' })
              }
            }
          } else {
            next()
          }
        }
      },
      {
        path: 'ordenes/editar',
        name: 'Editar Ordenes',
        component: () => import(/* webpackChunkName: "pos_edit_orders" */ '../views/pos/orders/KnFormOrder.vue'),
        props: true,
        meta: {
          requireAuth: true,
          adminAccess: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (to.meta.requireAuth) {
            if (!store.getters.isLoggedIn) {
              next({ name: 'Login' })
            } else {
              if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
                next()
              } else {
                next({ name: 'Acceso restringido' })
              }
            }
          } else {
            next()
          }
        }
      },
      {
        path: 'ordenes/solicitar-cancelacion',
        name: 'Solicitar Cancelacion',
        component: () => import(/* webpackChunkName: "pos_cancellation_request" */ '../views/pos/orders/KnCancellationOrder.vue'),
        props: true,
        meta: {
          requireAuth: true,
          adminAccess: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (to.meta.requireAuth) {
            if (!store.getters.isLoggedIn) {
              next({ name: 'Login' })
            } else {
              if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
                next()
              } else {
                next({ name: 'Acceso restringido' })
              }
            }
          } else {
            next()
          }
        }
      },
      {
        path: 'inventario',
        name: 'Inventario',
        component: () => import(/* webpackChunkName: "pos_inventory" */ '../views/pos/inventory/KnInventory.vue'),
        meta: {
          requireAuth: true,
          adminAccess: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (to.meta.requireAuth) {
            if (!store.getters.isLoggedIn) {
              next({ name: 'Login' })
            } else {
              if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
                next()
              } else {
                next({ name: 'Acceso restringido' })
              }
            }
          } else {
            next()
          }
        }
      },
      {
        path: 'inventario/nuevo',
        name: 'Nuevo Inventario',
        component: () => import(/* webpackChunkName: "pos_new_inventory" */ '../views/pos/inventory/KnFormInventory.vue'),
        props: true,
        meta: {
          requireAuth: true,
          adminAccess: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (to.meta.requireAuth) {
            if (!store.getters.isLoggedIn) {
              next({ name: 'Login' })
            } else {
              if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
                next()
              } else {
                next({ name: 'Acceso restringido' })
              }
            }
          } else {
            next()
          }
        }
      },
      {
        path: 'inventario/editar',
        name: 'Editar Inventario',
        component: () => import(/* webpackChunkName: "pos_edit_inventory" */ '../views/pos/inventory/KnFormInventory.vue'),
        props: true,
        meta: {
          requireAuth: true,
          adminAccess: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (to.meta.requireAuth) {
            if (!store.getters.isLoggedIn) {
              next({ name: 'Login' })
            } else {
              if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
                next()
              } else {
                next({ name: 'Acceso restringido' })
              }
            }
          } else {
            next()
          }
        }
      },
    ],
    meta: {
      requireAuth: true,
      adminAccess: true
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (to.meta.requireAuth) {
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' })
        } else {
          if (to.meta.adminAccess && (store.getters.isSuperAdmin || store.getters.isAdminEmployee)) {
            next()
          } else {
            next({ name: 'Acceso restringido' })
          }
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/acceso-restringido',
    name: 'Acceso restringido',
    component: () => import(/* webpackChunkName: "acceso_restringido" */ '../views/shared/KnAccessDenied.vue'),
    meta: {
      requireAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
