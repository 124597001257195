<template>
  <v-card
    min-width="300"
    rounded="lg"
  >
    <v-card-title>
      {{ title }}
      <v-spacer v-if="numCols === 12"></v-spacer>
      <!-- <v-btn
        v-if="numCols === 12"
        color="info"
        x-small
        plain
      >
        <span class="text-none">Ver más</span>
        <v-icon color="info">mdi-chevron-right</v-icon>
      </v-btn> -->
    </v-card-title>
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
    <v-divider v-if="numCols !== 12"></v-divider>
    <v-card-text>
      <v-row>
        <v-col v-if="showText">
          <span class="text-h4 black--text">{{ bigMount }}</span>
        </v-col>
        <v-col v-if="showGraph" :cols="colsGraph">
          <kn-chart
            :canvasId="keyGraph"
            :chartData="chartData" 
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer v-if="!showGraph"></v-spacer>
      <!-- <v-btn
        v-if="numCols !== 12"
        color="info"
        x-small
        plain
      >
        <span class="text-none">Ver más</span>
        <v-icon color="info">mdi-chevron-right</v-icon>
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import KnChart from './KnChart.vue'
export default {
  components: { KnChart },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    keyGraph: {
      type: String,
      default: 'g0'
    },
    showText: {
      type: Boolean,
      default: true
    },
    showGraph: {
      type: Boolean,
      default: true
    },
    colsGraph: {
      type: [String, Number],
      default: "6"
    },
    bigMount: {
      type: String,
      default: '$0.00'
    },
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    numCols() {
      return parseInt(this.colsGraph)
    }
  }
}
</script>

<style>

</style>