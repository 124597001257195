import axios from "axios";
import store from "../store";

const APIUrl = "https://kn.e32.tech"; // "http://127.0.01:8000";

const api = axios.create({
  baseURL: APIUrl,
  headers: { contentType: "application/json" },
});

const commonAPI = axios.create({
  baseURL: APIUrl,
  headers: { contentType: "application/json" },
});

api.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${store.state.apiTokens.access}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.dispatch("refreshToken");
      originalRequest.headers[
        "Authorization"
      ] = `Bearer ${store.state.apiTokens.access}`;
      return api.request(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { api, APIUrl, commonAPI };
