<template>
  <v-app>
    <kn-navbar v-if="!isLogin" />
    <v-main :class="backgroundColor">
      <v-scale-transition group>
        <router-view key="main-router"/>
      </v-scale-transition>
    </v-main>
    <kn-footer v-if="!isLogin" />
    <kn-alert />
  </v-app>
</template>

<script>
import KnFooter from './components/KnFooter.vue';
import KnNavbar from './components/KnNavbar.vue';
import { mapState } from 'vuex'
import KnAlert from './components/KnAlert.vue';
export default {
  components: { KnNavbar, KnFooter, KnAlert },
  name: 'App',
  
  data: () => ({
    //
  }),
  computed: {
    ...mapState(['isLogin', 'isPOS']),
    backgroundColor() {
      if (this.isLogin) {
        return 'primary'
      } else if (this.isPOS) {
        return 'gray3'
      }
      return ''
    }
  }
};
</script>
